/* General Styles */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  font-family: "Arial", sans-serif;
  background-color: #f8f9fa;
}

/* Apply text shadow for all elements to increase readability */
* {
  text-shadow: 1px 1px 2px black;
}

/* Remove text-shadow for form inputs, error messages, and solid backgrounds */
.form-control,
.error-message {
  text-shadow: none; /* No stroke for form fields and error messages */
}

/* Full-Screen Background */
.page-background {
  background: url("/src/images/HomeBg.png") no-repeat center center fixed;
  background-size: cover; /* Ensure the image covers the entire viewport */
  min-height: 100vh; /* Set the minimum height to the viewport height */
  width: 100vw; /* Full viewport width */
  overflow: auto; /* Enable content scrolling within the background */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

/* Override Bootstrap's navbar classes */
.navbar {
  position: fixed !important; /* Make sure the navbar stays fixed */
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100%; /* Ensure full width */
  background-color: rgba(52, 58, 64, 0.9); /* Semi-transparent background */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Add shadow for visibility */
}

.navbar-toggler {
  border: none;
  margin: 4px;
  margin-bottom: 8px;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,0.5)' stroke-width='2' linecap='round' linejoin='round' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

/* Jumbotron / Header Section */
.jumbotron {
  background: inherit; /* Use the same background as the page */
  width: 100%;
  min-height: 100vh; /* Ensure it covers the full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  color: white;
  text-align: center;
}

/* Form Styling */
.story-forms {
  background: inherit; /* Continue the page background */
  width: 90%;
  max-width: 1200px; /* Limit form width on larger screens */
  margin: 0 auto;
  padding: 2rem;
}

.form-group {
  width: 100%;
  margin-bottom: 1.5rem;
}

.form-control {
  width: 100%;
  padding: 0.8rem;
  font-size: 1rem;
}

/* Button Styling */
.btn-primary {
  background-color: #00b4d8;
  border: none;
  padding: 0.8rem 2rem;
  font-size: 1.2rem;
  color: white;
  cursor: pointer;
  display: inline-block;
}

.btn-primary:hover {
  background-color: #0077b6;
}

/* Footer Styling */
.footer {
  background-color: transparent;
  color: white;
  text-align: center;
  padding: 1rem;
  padding-bottom: 4px;
  width: 100%;
  position: fixed;
  bottom: 0;
}

/* Book Showcase */
.book-showcase {
  padding: 3rem 0;
  width: 100%;
}

.books-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.book-item {
  text-align: center;
  max-width: 200px;
}

.book-item img {
  max-width: 100%;
  border-radius: 5px;
  margin-bottom: 1rem;
}

.book-item h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.book-item a {
  color: #00b4d8;
  text-decoration: none;
}

.book-item a:hover {
  text-decoration: underline;
}

/* Contact Form */
.contact-form {
  width: 90%;
  max-width: 600px;
  margin: 2rem auto;
  text-align: center;
  padding: 2rem;
}

.contact-form h2 {
  margin-bottom: 1.5rem;
}

/* Dark Card for Contact Form */
.contact-card {
  background-color: rgba(52, 58, 64, 0.9);
  border-radius: 8px;
  padding: 2rem;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 95%;
  max-width: 800px;
  margin: 2rem auto;
  margin-top: 80px;
}

.form-control {
  width: 100%;
  padding: 1rem;
  margin-bottom: 1.5rem;
  border: 1px solid #1076dc;
  border-radius: 5px;
  font-size: 1rem;
}

/* Mobile Adjustments */
@media (max-width: 768px) {
  .page-background {
    background: url("/src/images/HomeBg.png") no-repeat center center fixed;
    background-size: cover; /* Ensure the image covers the entire viewport */
    min-height: 100vh; /* Set the minimum height to the viewport height */
    width: 100vw; /* Full viewport width */
    overflow: auto; /* Enable content scrolling within the background */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  /* Fix background scrolling on iOS/Android */
  @supports (-webkit-touch-callout: none) {
    .page-background {
      background-attachment: scroll;
    }

    body::before {
      content: "";
      background: url("/src/images/HomeBg.png") no-repeat center center;
      background-size: cover;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      display: block;
    }
  }

  /* Ensure the forms fit well on mobile devices */
  .jumbotron {
    margin-top: 24px;
    padding: 8px;
  }

  .navbar-nav {
    text-align: center;
    background-color: rgba(52, 58, 64, 0.9);
  }

  .navbar-nav .nav-link {
    padding: 5px;
    color: white;
  }

  .navbar-nav .nav-link:hover {
    background-color: #00b4d8;
  }

  .contact-form {
    padding: 15px;
  }

  .story-forms {
    background: inherit; /* Continue the page background */
    width: 100%;
    margin: 0;
    padding: 8px;
  }

  .form-group {
    width: 100%;
    margin-bottom: 1.5rem;
  }

  .form-control {
    width: 100%;
    padding: 8px;
    font-size: 1rem;
  }
}
